window.loaded = false;

var windowWidth = 0;
var contactMap = undefined;

var mainHeader = undefined;
var headerBorder = undefined;
var mapContent = undefined;
var bookingBox = undefined;

var homeSlider = undefined;



var carteItemContent = undefined;
var wineSelects = undefined;

$(document).ready(function () {
	/* MOBILE */
	//create js hook links
	windowWidth = window.innerWidth;
	var resizeFn = function () {
		if (windowWidth != window.innerWidth) {
			windowWidth = window.innerWidth;
			$(document).trigger('ikon.window_width_changed', windowWidth);

			if (typeof homeSlider !== 'undefined') {
				homeSlider.destroySlider();
				initHomeSlider(windowWidth < 1024 ? 'mobile' : 'desktop');
			}
		}
	}

	

	window.addEventListener('resize', resizeFn);

	$('.mailtolink').click(function (e) {
		handleMailtoLink($(this));
	});

	mainHeader = $('.main-header');
	headerBorder = $('[data-purpose="header-border"]');
	if (headerBorder.length === 0) {
		headerBorder = $('.main-header');
	}

	if (window.location.hash) {
		var scrollTop = $(window).scrollTop();
		$(window).scrollTop(scrollTop - mainHeader.outerHeight(true));
	}

	$('[data-purpose="navigation-link"]').each(function () {
		setNavigationUrl($(this));
	});

	$('[data-purpose="remodal-open"]').click(function () {
		var target = $(this).data('popup-target');
		if ($(this).data('popup-target')) {
			var item = $('[data-remodal-id="' + $(this).data('popup-target') + '"]');
			if (item.length > 0) {
				item.remodal().open();
			}
		}
	});

	if ($('.cookie-bar').length > 0) {

		$('.cookie-bar .button').click(function () {
			$('.cookie-bar').addClass('closed');

			$.ajax({
				url: ajax_controller + 'setCookieConfirm',
				dataType: 'json',
				type: 'post',
				data: {
					cookie: 1
				},
				success: function () {}
			});
		});
	}

	$('[data-purpose="remodal-close"]').click(function () {
		var value = $(this).data('value');
		if (value && value == 'confirm') {
			$('#terms-chk').attr('checked', true);
		}

		$(this).parents('.remodal').remodal().close();
	});

	$('[data-purpose="scroll-to-element"]').click(function () {
		var target = $(this).data('target');
		var item = $(target);
		var timeout = $(this).data('timeout');
		if (isNaN(timeout)) {
			timeout = 400;
		}


		if (item.length > 0) {
			$('html, body').animate({
				scrollTop: (item.offset().top - 85)
			}, timeout);
		}
	});

	$('[data-purpose="remodal-open"]').click(function () {
		var target = $(this).data('target');
		if (target) {
			openRemodalContent(target);
		}
	});

	$('[data-purpose="privacy-confirm-button"]').click(function () {
		var type = $(this).data('type');
		$('#privacyChk').prop('checked', type == 'accept');
		$('[data-remodal-id="privacyRemodal"]').remodal().close();
	});


	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			var type = window.innerWidth < 1024 ? 'mobile' : 'desktop';
			initHomeSlider(type);
		}

		if (site_id == 7) {
			loadBookingJs();
			initContactMap();
		}

		if (site_id == 4) {
			wineSelects = $('[data-purpose="wine-select"]');
			if (wineSelects.length > 0) {
				carteItemContent = $('#menuItemContent');
				wineSelects.change(function(){
					refreshMenuTable();
				});
			}
		}

		if (site_id != 7 && mainHeader) {
			var scrollFn = function() {
				if (headerBorder && headerBorder.length > 0) {
					var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
					var scrollTop = $(window).scrollTop();
					if (height >= scrollTop && mainHeader.hasClass('fixed')) {
						mainHeader.removeClass('fixed');
					} else if (height < scrollTop && !mainHeader.hasClass('fixed')) {
						mainHeader.addClass('fixed');
					}
				}
			}

			window.addEventListener('touchend', scrollFn);
			window.addEventListener('touchmove', scrollFn);
			window.addEventListener('scroll', scrollFn);

			var fix = mainHeader.find('> .inner-content').clone();
			fix.addClass('fixed-content');
			mainHeader.append(fix);
		}
	}

	if (typeof articleGalleryItems !== 'undefined') {
		$('.article-gallery-header-item').click(function () {
			openArticleGallery($(this));
		});
	}

	initGalleries();

	bookingBox = $('.BookingBoxContent');
});

function refreshMenuTable() {
	if (wineSelects.length > 0 && carteItemContent && carteItemContent.length > 0) {
		var datas = {};
		wineSelects.each(function() {
			datas[$(this).attr('name')] = $(this).val();
		});

		$.ajax({
			url: ajax_controller+'refresh_wines',
			dataType:'json',
			type:'post',
			data:datas,
			success: function(response) {
				carteItemContent.html(response.html);
			}
		});
	}
}

function initHomeSlider(type) {
	var touchDevice = isTouchDevice();
	
	var options = {
		auto:true,
		pause:4000,
		infiniteLoop: true,
		touchEnabled: touchDevice,
		preventDefaultSwipeX: true,
		preventDefaultSwipeY: false,
		controls: false,
		mode: 'fade',
		speed: 1200

	};

	if (!type) {
		type = 'desktop';
	}
	
	var selector = type == 'mobile' ? '#mobileHomeSlider' : '#desktopHomeSlider';
	
	homeSlider = $(selector).bxSlider(options);
}

function isTouchDevice() {
	var touchDevice = false;
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/)) {
		touchDevice = true;
	}
	return touchDevice;
}

function openRemodalContent(remodal_id) {
	var tmp = $('[data-remodal-id="' + remodal_id + '"]');
	if (tmp.length > 0) {
		tmp.remodal().open();
	}
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this,
			args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

function sendAnalyticsEvent(action, category, tag) {
	if (typeof gtag !== 'undefined' && action && category) {
		var evtData = {
			event_category: category,
		};

		if (tag && tag.trim() != '') {
			evtData.event_label = tag;
		}

		gtag('event', action, evtData);
	} else if (typeof ga != 'undefined' && action && category) {
		if (tag && tag.trim() != '') {
			ga('send', 'event', action, category, tag);
		} else {
			ga('send', 'event', action, category);
		}
	}
}

function svg(id) {
	return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}


function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}


function loadJs(url, implementationCode) {
	var scriptTag = document.createElement('script');
	scriptTag.src = url;

	scriptTag.onload = implementationCode;
	scriptTag.onreadystatechange = implementationCode;

	document.body.appendChild(scriptTag);
};


var checkInterval = undefined;

var checkBookingJs = function() {
	var select = $('#BookingPersoner');
	if (select && select.length > 0) {
		clearInterval(checkInterval);
		hackBookingWidget();
	}
}

function loadBookingJs() {
	var st = document.createElement('script');
	st.type = 'text/javascript';
	st.async = true;
	st.src = '//book.easytablebooking.com/javascripts/widget/v2/book.min.js';
	var sc = document.getElementsByTagName('script')[0];
	sc.parentNode.insertBefore(st, sc);
	
	checkInterval = setInterval(checkBookingJs, 100);
}



function hackBookingWidget() {
	if (bookingBox) {
		bookingBox.find('select').each(function(){
			var parent = $(this).parent();
			if (parent && !parent.hasClass('custom-select')) {
				var customSelect = $('<span>', {
					class:'custom-select',
					'html':$(this).detach()
				});
		
				parent.prepend(customSelect);
			}
		});

		bookingBox.find('input[type="button"]').each(function(){
			var parent = $(this).parent();
			if (parent && !parent.hasClass('buttons-row')) {
				parent.addClass('buttons-row');

				if (parent.hasClass('col2')) {
					var row = parent.parent();
					if (row && !row.hasClass('spaceafter')) {
						row.addClass('spaceafter');
					}

					if (!row.hasClass('button-row-outer')) {
						row.addClass('button-row-outer')
					}
				}
			}
			
			if (!$(this).hasClass('handled')) {
				$(this).addClass('button');
				$(this).addClass('handled');
				var clickFn = $(this).attr('onclick');
				if (clickFn) {
					clickFn = clickFn.replace(/;|\(|\)/g, '');
					$(this).data('click-function', clickFn);
					$(this).removeAttr('onclick');
					$(this).removeAttr('style');
					$(this).click(function(){
						handleEasyTableClick($(this));
					});
				}
			}
		});

		bookingBox.find('.link').each(function(){
			if (!$(this).hasClass('handled')) {
				$(this).addClass('handled');

				var parent = $(this).parent();
				if (parent && !parent.hasClass('button-row')) {
					parent.addClass('buttons-row');
					if (parent.hasClass('col2')) {
						var row = parent.parent();
						if (row && !row.hasClass('spaceafter')) {
							row.addClass('spaceafter');
						} 
					}
				}

				var clickFn = $(this).attr('onclick');
				if (clickFn) {
					clickFn = clickFn.replace(/;|\(|\)/g, '');
					$(this).data('click-function', clickFn);
					$(this).removeAttr('onclick');
					$(this).click(function(){
						handleEasyTableClick($(this));
					});
				}
			}
		});

		bookingBox.find('input[type="text"]:not(.field)').each(function(){
			$(this).addClass('field');
		});
	}
}

function handleEasyTableClick(btn) {
	var functionName = btn.data('click-function');
	
	if (functionName && typeof window[functionName] === 'function') {
		window[functionName]();
		hackBookingWidget();
	}
}


function initContactMap() {
	if (typeof mapboxgl !== 'undefined' && mapboxgl.supported()) {
		mapboxgl.accessToken = 'pk.eyJ1IjoiaWtvbnJlc3RhdXJhbnQiLCJhIjoiY2sxbHNnbTQ4MDhkejNubG9haW1pdnJ6dCJ9.Wv_RZoFTHFcVzOMVwRsoGg';

		contactMap = new mapboxgl.Map({
			container: 'contactMap',
			style: 'mapbox://styles/ikonrestaurant/ck1lskapc19yf1cmu3s1heni5',
			center: [21.62456, 47.52981],
			zoom: 15.5
		});

		contactMap.scrollZoom.disable();

		contactMap.on('load', function(){
			var el = document.createElement('div');
			el.className = 'map-marker';
			el.innerHTML = '<span class="marker-outer">'+svg('marker-icon')+'</span>';

			new mapboxgl.Marker(el)
				.setLngLat([21.62456, 47.52981])
				.addTo(contactMap);
		});
	} else {
		alert('Nincs térkép, a kurva életbe');
	}
}

function setNavigationUrl(item) {
	var address = item.data('address');

	if (address) {
		var protocol = 'google';
		var ua = navigator.userAgent.toLowerCase();
		var plat = navigator.platform;
		var device = ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i) ? ua.match(/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera/i)[0] : false;

		if (device == 'iphone' || device == 'ipad' || device == 'ipod') {
			function iOSversion() {

				if (/iP(hone|od|ad)/.test(navigator.platform)) {
					var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
					return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
				}
			}

			var ver = iOSversion() || [0];
			if (ver[0] >= 6) {
				protocol = 'apple';
			}
		}

		var urlSuffix = '/?daddr=' + address;
		var nav_url = '';
		switch (protocol) {
			case 'apple':
				nav_url = 'maps://';
				break;
			default:
				nav_url = 'https://maps.google.com/maps';
				break;
		}
		item.attr('target', '_blank');
		item.attr('href', nav_url + urlSuffix);
	}
}


function openGallery(item) {
	var groupId = item.data('group-id');
	var index = item.data('index');

	if (typeof galleryItems === 'object' && typeof galleryItems[groupId] !== 'undefined') {
		if (!index) {
			index = 0;
		}

		initGallery(item, galleryItems[groupId], index);
	}
}

function openArticleGallery(item) {
	var groupId = $(item).data('gallery-id');
	if (groupId && typeof articleGalleryItems === 'object' && typeof articleGalleryItems[groupId] !== 'undefined') {
		initGallery(item, articleGalleryItems[groupId]);
	}
}

function initGallery(target, items, index) {
	if (!index) {
		index = 0;
	}

	$(target).lightGallery({
		dynamic: true,
		dynamicEl: items,
		index: index,
		hash: false,
		download: false
	});
}

function getMapStyle() {
	return [];
}

function initGalleries() {
	if (typeof mediaGroupItems === 'object' && Object.keys(mediaGroupItems).length > 0) {
		$('[data-purpose="gallery-box-item"]:not(.initialized)').each(function () {
			$(this).addClass('initialized');
			$(this).click(function () {
				var id = $(this).data('group-id');
				if (id && typeof mediaGroupItems[id] !== 'undefined') {
					var index = $(this).data('index');
					if (!index) {
						index = 0;
					}

					initGallery($(this), mediaGroupItems[id], index);
				}
			});
		});
	}
}
