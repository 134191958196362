(function(wnd) {
  function mobileMenu(button, params) {
    try {
      if (!button) {
        throw "I need a button!";
      }

      if (params) {
        this.setParams(params);
      }

      this.setButton(button);

      this.init();
    } catch (e) {
      console.log(e);
    }
  }

  mobileMenu.prototype = {
    button: undefined,
    opened: false,
    wrapper: undefined,
    floatingPanel: undefined,
    sites: [],
    extraItems: {
      top: [],
      bottom: []
    },
    texts: {
      close: 'bezárás',
      back: 'vissza'
    },
    type: 'full',
    posX: 0,
    posY: 0,
    sens: 60,
    swipeAvailable: false,
    swipeTargets: ['mobile-submenu', 'mobile-menu-content'],
    scrollTop: 0,
    createContent: true,
    init: function() {
      if (this.createContent) {
        this.createDOM();
      } else {
        this.setElements();
        this.container.removeAttr('style');
      }
      this.addDOMEvents();
    },
    createDOM: function() {
      this.container = this.createContainer();
      this.closeBtn = this.createCloseBtn();
      this.content = this.createMenuItems();

      this.container.append(this.closeBtn);
      this.container.append(this.content);

      this.button.parent().append(this.container);
      this.overlay = $('<div>', {
        'class': 'mobile-menu-overlay'
      });

      $('body').append(this.overlay);
    },
    setElements: function() {
      this.overlay = $('[data-purpose="mobile-overlay"]');
      this.container = $('[data-purpose="mobile-menu-content"]');
      this.closeBtn = this.container.find('[data-purpose="close-menu"]');
      this.wrapper = $('#wrapper');
      this.floatingPanel = $('[data-purpose="floating-menu"]');
    },
    addDOMEvents: function() {
      var _self = this;
      /* global events */
      this.container.find('[data-purpose="scroll-to-element"]').click(function(){
        _self.changeMenuVisibility();
      });
      this.button.click(function() {
        _self.changeMenuVisibility();
      });

      this.overlay.click(function() {
        _self.changeMenuVisibility();
      });

      this.closeBtn.click(function(){
        _self.changeMenuVisibility();
      });

      
      /* Type = slide */
      if (this.getType() == 'slide') {
        $('.has-submenu .mobile-menu-item').click(function() {
          if (!$(this).hasClass("mobile-submenu-item")) {
            _self.scrollTop = _self.container.scrollTop();
            _self.container.scrollTop(0);
            $(this).parent().addClass("opened-submenu");
          }
        });

        $('.mobile-submenu .back-link').click(function() {
          _self.closeSubmenu($(this).parents('.has-submenu'));
        });

        if (this.isSwipeAvailable()) {
          window.addEventListener('touchstart', function(e) {
            _self.touchFunction(e, 'start');
          });

          window.addEventListener('touchend', function(e) {
            _self.touchFunction(e, 'close');
          });

          window.addEventListener('touchmove', function(e) {
            _self.touchFunction(e, 'move');
          });
        }
      }
    },
    touchFunction: function(evt, type) {
      var parents = this.availableSwipeTarget('mobile-submenu') ? $(evt.target).parents('.mobile-submenu') : [];
      var parents2 = this.availableSwipeTarget('mobile-menu-content') ? $(evt.target).parents('.mobile-menu-content') : [];

      var validTarget = false;
      var menu = false;

      if ($(evt.target).hasClass('submenu-inner-content')) {
        validTarget = true;
      }

      if (parents.length > 0 || ($(evt.target).hasClass('mobile-submenu') && this.availableSwipeTarget('mobile-submenu'))) {
        validTarget = true;
      } else if (parents2.length > 0 || ($(evt.target).hasClass('mobile-menu-content') && this.availableSwipeTarget('mobile-menu-content'))) {
        validTarget = true;
        menu = true;
      }

      if (validTarget) {
        var origEvent = typeof evt.originalEvent != 'undefined' ? evt.originalEvent : evt.changedTouches;
        if (typeof origEvent[0] != 'undefined') {
          var posX = origEvent[0].pageX;
          var posY = origEvent[0].pageY;

          if (type == 'start') {
            this.container.addClass('move');
            this.wrapper.addClass('move');
            this.floatingPanel.addClass('move');
            this.setPosX(posX);
            this.setPosY(posY);
          } else if (type == 'close') {
            this.container.removeClass('move');
            this.wrapper.removeClass('move');
            this.floatingPanel.removeClass('move');
            var changeX = this.getPosX() - posX;
            var changeY = Math.abs(posY - this.getPosY());

            var closable = Math.abs(changeX) > changeY && changeX <= (this.getSens() * -1);

            var item = menu ? this.container : $('.has-submenu.opened-submenu .mobile-submenu');
            var height = item.height();

            item.removeAttr('style');
            this.wrapper.removeAttr('style');
            this.floatingPanel.removeAttr('style');

            if (closable) {
              if (menu && this.isOpened()) {
                this.changeMenuVisibility();
              } else {
                this.closeSubmenu($('.has-submenu.opened-submenu'));
              }
            }

            this.setPosX($(window).width());
            this.setPosY($(window).height());
          } else if (type == 'move') {
            var diffX = posX - this.getPosX();
            var diffY = posY - this.getPosY();
            
            var item = menu ? this.container : $('.has-submenu.opened-submenu .mobile-submenu');
            if (Math.abs(diffY) < Math.abs(diffX)) {
              evt.preventDefault();
              if (diffX > 5) {
                item.css("transform", 'translateX(' + diffX + 'px)');
                this.wrapper.css("transform", 'translateX(-'+(item.width() - diffX)+'px)');
                this.floatingPanel.css("transform", 'translateX(-'+(item.width() - diffX - this.button.width())+'px)');
              }
            }
          }
        }

      }
    },
    closeSubmenu: function(menuItem) {
      if (menuItem.length > 0) {
        this.container.scrollTop(this.scrollTop);
        menuItem.removeClass('opened-submenu');
      }
    },
    changeMenuVisibility: function() {
      if (this.isOpened()) {
        this.setOpened(false);
        this.container.scrollTop(0);
        this.button.removeClass('is-active');
        $('body').removeClass('opened-mobile-menu');
        $('.has-submenu.opened-submenu').removeClass('opened-submenu');
        this.container.removeClass('opened');
        this.container.removeClass('animated');
        $('#wrapper').off('click');
      } else {
        var _self = this;
        this.setOpened(true);
        this.button.addClass('is-active');
        $('body').addClass('opened-mobile-menu');
        this.container.addClass('opened');  
        setTimeout(function(){
          $('#wrapper').click(function(e){
            if (e.pageX < (window.innerWidth - _self.container.width())) {
              _self.changeMenuVisibility();
            }
          });
        }, 50);

        setTimeout(function(){
          _self.container.addClass('animated');
        }, 300);
      }
    },
    createContainer: function() {
      return $('<div>', {
        'class': 'mobile-menu-content ' + this.getType()
      });
    },
    createCloseBtn: function() {
      return $('<a>', {
        class: 'close-btn',
        html: svg('close-icon'),
        title: this.getText('close')
      });
    },
    createMenuItems: function() {
      var outer = $('<div>', {
        'class': 'mobile-menu-inner mobile-mainmenu',
      });

      if (this.sites.length > 0) {
        for (var i = 0; i < this.sites.length; i++) {
          var groupOuter = $('<div>', {
            'class': 'mobile-menu-group'
          });
          var sites = this.sites[i];
          if (sites.length > 0) {
            for (var k = 0; k < sites.length; k++) {
              var hasChild = sites[k].children && Object.keys(sites[k].children).length > 0 ? true : false;
              var item = $('<div>', {
                class: 'menu-item-outer ' + (hasChild ? 'has-submenu' : ''),
              });

              if (typeof sites[k].content === 'undefined') {
                var innerItem = $('<a>', {
                  class: 'mobile-menu-item ' + (typeof sites[k].class != 'undefined' ? sites[k].class : ''),
                  href: sites[k].link,
                  html: sites[k].name + (sites[k].children && Object.keys(sites[k].children).length > 0 ? svg('small-right-arrow') : '')
                });

                if (typeof sites[k].attributes != 'undefined') {
                  $.each(sites[k].attributes, function(key, value) {
                    innerItem.attr('data-' + key, value);
                  });
                }
                item.append(innerItem);


                if (sites[k].children && Object.keys(sites[k].children).length > 0) {
                  var submenuInner = this.createSubmenuItem(sites[k].children);
                  var submenu = $('<div class="mobile-submenu">');
                  submenu.append(submenuInner);
                  item.append(submenu);
                }
              } else {
                item.append(sites[k].content);
              }
              groupOuter.append(item);
            }
          }
          outer.append(groupOuter);
        }
      }

      return outer;
    },
    createSubmenuItem: function(children) {
      var submenu = $('<div>', {
        'class': 'submenu-inner-content'
      });

      var keys = Object.keys(children);

      if (keys.length > 0) {

        var backLink = $('<div>', {
          'class': 'menu-item-outer submenu-item-outer',
          'html': $('<a>', {
            'class': 'mobile-menu-item mobile-submenu-item back-link',
            'href': 'javascript:void(0)',
            'html': svg('small-right-arrow') + this.getText('back')
          })
        });

        submenu.append(backLink);


        for (var i = 0; i < keys.length; i++) {
          if (typeof children[keys[i]].items != 'undefined' && children[keys[i]].items.length > 0) {
            var submenuGroup = $('<div>', {
              'class': 'submenu-group ' + keys[i]
            });

            for (var k = 0; k < children[keys[i]].items.length; k++) {
              var childItem = children[keys[i]].items[k];
              var item = $('<div>', {
                'class': 'menu-item-outer submenu-item-outer',
              });
              var inner = $('<a>', {
                'class': 'mobile-menu-item mobile-submenu-item',
                'href': childItem.link,
                'html': childItem.name
              });

              if (typeof childItem.attributes != 'undefined') {
                $.each(childItem.attributes, function(key, value) {
                  inner.attr('data-' + key, value);
                });
              }
              item.append(inner);
              submenuGroup.append(item);
            }

            submenu.append(submenuGroup);
          }
        }
      }

      return submenu;
    },
    setParams: function(params) {
      if (params.sites) this.setSites(params.sites);
      if (params.type) this.setType(params.type);
      if (params.sens) this.setSens(params.sens);
      if (params.extraItems) this.setExtraItems(params.extraItems);
      if (params.texts) this.setTexts(params.texts);
      if (params.swipeAvailable) this.setSwipeAvailable(params.swipeAvailable);
      if (typeof params.createContent !== 'undefined') this.setCreateContent(params.createContent);
    },
    setCreateContent: function(createContent) {
      this.createContent = createContent;
    },
    setOpened: function(value) {
      this.opened = value;
    },
    setButton: function(button) {
      this.button = button;
    },
    setSites: function(sites) {
      this.sites = sites;
    },
    setType: function(type) {
      this.type = type;
    },
    setPosX: function(x) {
      this.posX = x;
    },
    setPosY: function(y) {
      this.posY = y;
    },
    setSens: function(sens) {
      this.sens = sens;
    },
    setExtraItems: function(extraItems) {
      this.extraItems = $.extend({}, this.extraItems, extraItems);
    },
    setTexts: function(texts) {
      this.texts = $.extend({}, this.texts, texts);
    },
    setSwipeAvailable: function(swipeAvailable) {
      this.swipeAvailable = swipeAvailable;
    },
    getSites: function() {
      return this.sites;
    },
    getButton: function() {
      return this.button;
    },
    getType: function() {
      return this.type;
    },
    getPosX: function() {
      return this.posX;
    },
    getPosY: function() {
      return this.posY;
    },
    getSens: function() {
      return this.sens;
    },
    getExtraItems: function(key) {
      if (key)
        return typeof this.extraItems[key] != 'undefined' ? this.extraItems[key] : false;

      return this.extraItems;
    },
    getText: function(key) {
      return typeof this.texts[key] != 'undefined' ? this.texts[key] : '';
    },
    isOpened: function() {
      return this.opened;
    },
    isSwipeAvailable: function() {
      return this.swipeAvailable;
    },
    availableSwipeTarget: function(key) {
      return this.swipeTargets.indexOf(key) >= 0 ? true : false;
    }
  }

  wnd.mobileMenu = mobileMenu;

})(window);
