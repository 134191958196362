(function(window){
    function IkonImageLoader() {
        try {
            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    IkonImageLoader.prototype = {
        parallaxItems:[],
        init: function() {
          this.loadImages();
          this.setElements();
          this.addDOMEvents();
        },
        setElements: function() {
            var _self = this;
            $('[data-purpose="parallax-content"]').each(function(){
                if (isTouchDevice()) {
                    $(this).addClass('touch-device');
                }
                _self.parallaxItems.push($(this));
            });

        },
        loadImages: function() {
            this.loadImageItems();
            this.loadImageBackgrounds();
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.parallaxItems.length > 0 && !isTouchDevice()) {
                $(window).on('scroll', function(){
                    _self.checkParallax();
                });
            }
        },
        checkParallax: function() {
            var _self = this;

            var scrollTop = $(window).scrollTop();
            var windowHeight = window.innerHeight;
            var windowWidth = window.innerWidth;
            
            var currentBottom = scrollTop + windowHeight;

            var max = windowWidth * 0.23;
            if (max > 100) {
                max = 100;
            }
            var min = max * -1;
            
            for (var i = 0; i < this.parallaxItems.length; i++) {
                var item = this.parallaxItems[i];
                if (windowWidth >= 600) {
                    var parent = item.parent();
                    var top = parent.offset().top;
                    var height = parent.outerHeight(true);
                    var endPosition = top+height;
                    
                    if (top <= currentBottom && endPosition >= scrollTop) {
                        var parallaxVal = (scrollTop - top) / 8;
                        
                        if (parallaxVal < min) {
                            parallaxVal = min;
                        }

                        if (parallaxVal > max) {
                            parallaxVal = max;
                        }
                        
                        item.attr('style', 'transform:translate3d(0px, '+parallaxVal+'px, 0px);');
                    }
                } else {
                    item.removeAttr('style');
                }
            }
        },
        loadImageItems: function() {
            $('[data-purpose="after-load-image"]').each(function(){
                var src = $(this).data('src');
                if (src) {
                    $(this).attr('src', src);
                    var parentClass = $(this).data('extra-parent-class');
                    $(this).on('load', function(){
                        $(this).removeAttr('data-purpose');
                        $(this).addClass('loaded');

                        if (parentClass) {
                            $(this).parent().addClass(parentClass);
                        }
                    });
                }
            });

            $('[data-purpose="after-load-image-from-span"]').each(function(){
                var src = $(this).data('src');
                var alt = $(this).data('alt');
                var classList = $(this).attr('class');

                if (src) {
                    var options = {
                        src:src,
                        alt: alt === undefined ? '' : alt,
                    };
                    
                    if (classList) {
                        options.class = classList;
                    }

                    var img = $('<img />', options);

                    img.insertAfter($(this));
                }

                $(this).remove();
            });
        },
        loadImageBackgrounds: function() {
            var _self = this;
            $('[data-purpose="image-bg-load"]:not(.initialized)').each(function(){
                var image = $(this).data('image');
                if (image) {
                    var parentClass = $(this).data('extra-parent-class');
                    var style = $(this).attr('style');
                    if (!style) {
                        style = '';
                    }

                    style += 'background-image:url("'+image+'")';
                    $(this).attr('style', style);

                    if (parentClass) {
                        var parentSelector = $(this).data('parent-selector');
                        if (parentSelector) {
                            $(this).parents(parentSelector).addClass(parentClass);
                        } else {
                            $(this).parent().addClass(parentClass);
                        }
                    }
                }

                $(this).addClass('initialized');
            });
        }
    };

    window.IkonImageLoader = IkonImageLoader;
})(window);
